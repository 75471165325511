import React, { Component } from 'react';
import BarChart from "./BarChart";
import db from "./util/sql_init"
import RadarChart from "./RadarChart";
import ChartContainer from "./ChartContainer";
import ChartContainerNeat from "./ChartContainerNeat";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Navbar} from "react-bootstrap"

const styles={
  row:{
      maxWidth:"100vw",
      display:"flex",
      flexDirection:"row",
  },
  col:(v) => ({
      padding:"2%",
      flex:`${v} 1 auto`
  }),
    gutter:(v) => ({
        padding:"2%",
        flex:`${v} 1`
    })

}

const copy = {
    perspective: {
        WSC:{
            title:"WSC",
            text: "This view shows the overall alignment between the Water Sensitive City vision and the SDGs.  It takes the goals of a WSC as the primary focus, and demonstrates to what extent each WSC goal contributions to realisation of each of the SDGs.  For users interested in advancing WSC in a way that also connects to the SDG agenda (or demonstrating how they are contributing to SDG implementation), this reveals which WSC goals will provide the strongest foundation for SDG realisation. This insight can help guide action accordingly. "
        },
        SDG:{
            title: "SDG",
            text: "This view takes the SDGs as the primary focus and shows which goals of a WSC will provide the strongest foundation for meeting the targets of that SDG.  Different combinations of SDGs can be chosen (depending on organisation or city priority) and the results will demonstrate which WSC goals will provide the maximum benefit.  This can help users to prioritise actions and direct time and funding appropriately."
        }
    },
    chart_type: {
        Bar:{
            title:"Key",
            text: <div><b>Green Shading</b> - used when realising a WSC indicator will automatically help meet the SDG target<br/>
                <b>Yellow Shading</b> - used where there is potential for a WSC indicator to be implemented in a way that helps meet an SDG target, but requires some deliberate effort at the planning stage to ensure alignment.</div>
        },
        Radial:{
            title: "Key",
            text: <div><b>Green Shading</b> - used when realising a WSC indicator will automatically help meet the SDG target<br/>
                <b>Yellow Shading</b>- used where there is potential for a WSC indicator to be implemented in a way that helps meet an SDG target, but requires some deliberate effort at the planning stage to ensure alignment.</div>
        }
    }
}

class ChartPage extends Component {
    constructor(){
        super();
        this.state = {
            chart_type: "Bar",
            perspective: "SDG"
        };
    }

    handleChartTypeChange = (t) => {
        this.setState({
            chart_type: t
        })
    }

    handlePerspectiveChange = (t) => {
        this.setState({
            perspective: t
        })
    }
  render() {
    return (
      <div>

        <div style={styles.row}>
            <div style={styles.col(5)}>
                <div style={styles.row}>
                    <h2>{copy.perspective[this.state.perspective].title}</h2>
                </div>
                <div style={styles.row}>
                    {copy.perspective[this.state.perspective].text}
                </div>

            </div>
            <div style={styles.col(5)}>
                <div style={styles.row}>
                    <h2>{copy.chart_type[this.state.chart_type].title}</h2>
                </div>
                <div style={styles.row}>
                    {copy.chart_type[this.state.chart_type].text}
                </div>

            </div>
        </div>
        <div style={{...styles.row, height:"80vh",}}>
            <div style={styles.col(1)}>
                <ChartContainerNeat
                    chartTypeCallback={this.handleChartTypeChange}
                    perspectiveCallback={this.handlePerspectiveChange}
                />
            </div>
        </div>
      </div>
    );
  }
}



export default ChartPage;
