import React, { Component } from 'react';
import BarChart from "./BarChart";
import db from "./util/sql_init"
import RadarChart from "./RadarChart";
import ChartContainer from "./ChartContainer";
import ChartContainerNeat from "./ChartContainerNeat";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Navbar} from "react-bootstrap"
import ChartPage from "./ChartPage";
import LandingPage from "./LandingPage";

const styles={
  row:{
      maxWidth:"100vw",
      display:"flex",
      flexDirection:"row",
  },
  col:(v) => ({
      padding:"2%",
      flex:`${v} 1 auto`
  }),
    gutter:(v) => ({
        padding:"2%",
        flex:`${v} 1`
    })

}

class App extends Component {
    constructor(){
        super();
        this.state = {
            landingPage: true,
        }
    }
  render() {
    return (
      <div style={{margin:"0% 10%"}} className="App">
          <Navbar>
              <Navbar.Brand>
                  <img src={"/static/crcwsc-logo.png"}
                    width={"250"}
                  />
                     SDG-Indicator
              </Navbar.Brand>
          </Navbar>
          {this.state.landingPage ?
            <LandingPage handleContinueClick={_=>this.setState({landingPage: false})}/>
           :
            <ChartPage/>
          }
      </div>
    );
  }
}



export default App;
