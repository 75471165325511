import {Component, } from "react"
import React from "react";
import db from "./util/sql_init";
import ReactEcharts from "echarts-for-react";

class HoverableText extends Component {

    state = {
        show: false
    }

    constructor(){
        super();
    }



    render() {

        let hoverStyle = (show) => ({
            position: "absolute",
            top:!this.props.bottom ? 0 : "auto",
            bottom:this.props.bottom ? 0 : "auto",
            background:"white",
            zIndex:10,
            border:"solid 1px black",
            fontSize:"140%",
            left:0,
            right:0,
            pointerEvents: "none",
            opacity: show ? 1 : 0,
            transition: "0.4s ease-out all"
        })
        let text = this.props.text;
        let limit = this.props.limit;
        let display_text = "";
        let show_hover = false;

        if(text.length > limit){
            display_text = text.substr(0, limit) + "...";
            show_hover = true;
        }else{
            display_text = text;
        }
        return (
            <div
                style={{position:"relative", flex:"1 1", minWidth:0}}
                onMouseEnter={_ => this.setState({show: true})}
                onMouseLeave={_ => this.setState({show: false})}
            >
                <div style={{overflow:"hidden", textOverflow:"ellipsis", whiteSpace:"nowrap"}}>
                    {display_text}
                </div>
                <div style={hoverStyle(this.state.show && show_hover)}>
                    {text}
                </div>
            </div>
        );
    }
}

export default HoverableText;