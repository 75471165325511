import {Component, } from "react"
import React from "react";
import db from "./util/sql_init";
import ReactEcharts from "echarts-for-react";


export function vh(v) {
    var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    return (v * h) / 100;
}

export function vw(v) {
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return (v * w) / 100;
}

export function vmin(v) {
    return Math.min(vh(v), vw(v));
}

export function vmax(v) {
    return Math.max(vh(v), vw(v));
}

export function splitValue(value, index) {
    return [value.substring(0, index), value.substring(index)];
}

class RadarChart extends Component {

    state = {
        greenHidden: false,
        yellowHidden: false,

    };

    constructor(){
        super();
    }


    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
    };


    handleLegendChange = (state) => {
        let greens, yellows, reds;
        greens = !state.selected.Greens;
        yellows = !state.selected.Yellows;
        reds = !state.selected.Reds;
        this.setState({
            greenHidden: greens,
            yellowHidden: yellows,
            redHidden: reds,
        })
    }

    render() {
        let res = this.props.formattedData;
        let keys = [];
        if(!this.state.greenHidden){
            keys.push("green");
        }
        if(!this.state.yellowHidden){
            keys.push("yellow");
        }

        let max = Math.max(...res.map(f => keys.map(k => 100*f[k]).reduce((acc, cur) => acc+cur)))

        let categories = res.map(v => v.name);

        let max_val = max;
        console.log(vmin(2))
        max_val = max_val.toFixed(2)
        return (
                    <ReactEcharts
                        onEvents={{
                            legendselectchanged: this.handleLegendChange,
                        }}
                        style={{
                            height:"100%"
                        }}
                        option = {{
                            chart:{
                                type: 'bar',
                            },
                            polar:{
                                center:["50%", "50%"],
                                containLabel:true,
                                radius:"60%",
                            },
                            title: {
                                text: this.props.title,

                                right:"45%",
                            },
                            tooltip:{
                                trigger:"axis",
                                formatter: function (params) {
                                    let format_name = (str) => {
                                        if(str.length < 100){
                                            return str;
                                        }
                                        let split_indexs = [0];
                                        let space_indices = [];
                                        for(let i = 0; i < str.length; i++){
                                            if(str.charAt(i) === " "){
                                                space_indices.push(i);
                                            }
                                        }

                                        for(let i = 100; i < str.length; i+=100){
                                            let greatest_lower = space_indices.map((v,i) => [v,i]).filter(o => o[0] < i).pop();
                                            let least_upper = space_indices.map((v,i) => [v,i]).filter(o => o[0] >= i)[0];
                                            greatest_lower = greatest_lower || 0;
                                            least_upper = least_upper || str.length;
                                            let du = least_upper[0] - i;
                                            let dl = i - greatest_lower[0];
                                            if(du < dl){
                                                split_indexs.push(least_upper[0])
                                            }else{
                                                split_indexs.push(greatest_lower[0])
                                            }
                                        }

                                        split_indexs.push(str.length)
                                        let final_string = "";
                                        for(let i = 0; i < split_indexs.length - 1; i++){
                                            let current = split_indexs[i];
                                            let next = split_indexs[i+1];
                                            final_string += str.substring(current, next) + "<br/>";
                                        }
                                        return final_string
                                    }

                                    let s = "<div>" + format_name(params[0].name)+ "</br>";
                                    let sum = 0;

                                    for(let i = 0; i < params.length; i++){
                                        sum += params[i].value;
                                    }
                                    for(let i = 0; i < params.length; i++){
                                        s += params[i].seriesName + ": " + params[i].value.toFixed(1) + "% ("+ (100*params[i].value/(sum === 0 ? 1 : sum)).toFixed(1) +"%)</br>";
                                    }
                                    s += "Sum: " + sum.toFixed(1) + "%</br>";
                                    s += "</div>";
                                    return s;
                                }
                            },
                            angleAxis: {
                                type:"category",
                                data: categories,
                                scale: true,
                                z:10,
                                nameTextStyle: {
                                    nameLocation:"start"
                                },
                                axisLine: {
                                  show:false,
                                },
                                startAngle: 1,
                                axisLabel:{
                                    fonSize: vmin(0.5),
                                    align:'center',
                                    verticalAlign: 'middle',
                                    formatter:(str)=>{
                                        if(str.length < 20){
                                            return str;
                                        }else if(str.length < 70){
                                            str =  str.substring(0, 70);
                                        }else{
                                            str =  str.substring(0, 70) +"...";
                                        }
                                        let split_indexs = [0];
                                        let space_indices = [];
                                        for(let i = 0; i < str.length; i++){
                                            if(str.charAt(i) === " "){
                                                space_indices.push(i);
                                            }
                                        }

                                        for(let i = 20; i < str.length; i+=20){
                                            let greatest_lower = space_indices.map((v,i) => [v,i]).filter(o => o[0] < i).pop();
                                            let least_upper = space_indices.map((v,i) => [v,i]).filter(o => o[0] >= i)[0];
                                            greatest_lower = greatest_lower || 0;
                                            least_upper = least_upper || str.length;
                                            let du = least_upper[0] - i;
                                            let dl = i - greatest_lower[0];
                                            if(du < dl){
                                                split_indexs.push(least_upper[0])
                                            }else{
                                                split_indexs.push(greatest_lower[0])
                                            }
                                        }
                                        split_indexs.push(str.length)
                                        let final_string = "";
                                        for(let i = 0; i < split_indexs.length - 1; i++){
                                            let current = split_indexs[i];
                                            let next = split_indexs[i+1];
                                            final_string += str.substring(current, next) + "\n";
                                        }
                                        return final_string
                                    }
                                },
                                splitLine:{
                                    show: true,
                                    lineStyle:{
                                        width:1,
                                        color:"#AAA"
                                    }
                                },
                            },
                            radiusAxis: {
                                min:0,
                                max:max_val,
                                splitNumber: 10,
                                interval: max_val/10,
                                z: 10,
                                axisLine:{
                                    show:false,
                                },
                                axisTick:{
                                  show:false,
                                },
                                splitLine:{
                                    show:true,
                                    lineStyle:{
                                        width:1,
                                        color: "#AAA"
                                    }
                                },
                                axisLabel: {
                                    show:false,
                                },
                            },
                            legend: {

                                top: "5%",
                            },
                            plotOptions: {
                                series: {
                                    stacking: 'normal'
                                },
                                bar: {
                                }
                            },
                            series: [{
                                id:"greens",
                                name: 'Greens',
                                type: "bar",
                                barGap:"0%",
                                barCategoryGap: "0%",
                                stack: "main",
                                coordinateSystem:"polar",
                                color:"#9ECD5E",
                                data: res.map(v => v.green*100*v.weight)
                            },{
                                id:"yellows",
                                name: 'Yellows',
                                type: "bar",
                                barGap:"0%",
                                barCategoryGap: "0%",
                                stack: "main",
                                coordinateSystem:"polar",
                                color: "#FFDD35",
                                data: res.map(v => v.yellow*100*v.weight)
                            }, ]
                        }}


                    />

        );
    }
}

export default RadarChart;