import {Component, } from "react"
import React from "react";
import db from "./util/sql_init";
import ReactEcharts from "echarts-for-react";
import RadarChart from "./RadarChart";
import BarChart from "./BarChart";
import HoverableText from "./HoverableText";
import {Dropdown} from "react-bootstrap"
import {DropdownButton} from "react-bootstrap"
import {ButtonToolbar, Col, Container, ListGroup, Row, FormCheck, ButtonGroup, Button, Collapse} from "react-bootstrap";


const styles = {
    btn: {
        color:"white",
        flex:1,
        fontSize:"0.85rem",
        textOverflow:"ellipsis",
        backgroundColor:"#007789",
        borderColor: "#007789"
    },
    btn_color: (c="auto", tc ="white") => ({
        color: tc,
        backgroundColor:c,
        borderColor: c,
    })
}

class ChartContainerNeat extends Component {

    state = {
        focus_filter: [],
        focusCategory: null,
        initialOptions: {},
        focusCategoryOptions: [],
        chartCategory: null,
        chartCategoryOptions: [],
        chartType: "Bar",
        perspective: "SDG",
        settingsOpen: false,
        greenHidden: false,
        yellowHidden: false,

    };

    constructor(){
        super();
        let filter_list = db.exec("column of select distinct Goal.name as name from Goal");
        this.state.focusCategoryOptions = filter_list;
        filter_list = db.exec("column of select distinct Indicator.name as name from Indicator");
        this.state.chartCategoryOptions = filter_list;
    }

    handleFilterClick = (key, event) => {
        let new_filter = [...this.state.focus_filter];
        let index = new_filter.indexOf(key);

        if(index >= 0){
            new_filter.splice(index, 1);
        }else{
            if(index < 0){
                new_filter.push(key);
            }
        }

        this.setState({
            focus_filter: new_filter,
        })
    }


    handleFocusChange = (e) => {
        this.setState({
            focusCategory: e === "Overview" ? null : e,
            focus_filter: [],
        });
    }

    handleChartTypeChange = (e) => {
        console.log(e)
        this.props.chartTypeCallback(e);
        this.setState({
            chartType: e,
        });
    };

    handleChartCategoryChange = (e) => {
        this.setState({
            chartCategory: e === "Overview" ? null : e,
        });
    }


    handlePerspectiveChange = (e) => {
        console.log(e)
        this.props.perspectiveCallback(e);
        let table_name = e === "SDG" ? "Goal" : "Indicator"
        let data_name = e  === "SDG" ? "Indicator": "Goal";
        this.setState({
            perspective: e,
            focus_filter: [],
            focusCategory: null,
            focusCategoryOptions: db.exec(`column of select distinct ${table_name}.name as name from ${table_name}`),
            chartCategory: null,
            chartCategoryOptions: db.exec(`column of select distinct ${data_name}.name as name from ${data_name}`)
        });

    }

    formatData = () => {
        let perspective_name = this.state.perspective === "SDG" ? "Goal" : "Indicator";
        let data_name = this.state.perspective === "SDG" ? "Indicator" : "Goal";

        let IF = (c,s) => c ? s : "";

        let subfocus = this.state.focusCategory !== null;
        let subchart = this.state.chartCategory !== null;
        let res = db.exec(`SELECT COUNT(IF(val = 1, 1, null)) as yellow, COUNT(IF(val = 2, 1, null)) as green, (COUNT(IF(val = 1, 1, null)) + COUNT(IF(val = 2, 1, null)))/((COUNT(val))) as weight, ${IF(subchart, "Sub") + data_name}.name as name
                            FROM Links
                              JOIN Indicator on Indicator.id = Links.indicator_id 
                              JOIN Goal on Goal.id = Links.goal_id 
                              JOIN SubGoal on SubGoal.id = Links.subgoal_id 
                              JOIN SubIndicator on SubIndicator.id = Links.subindicator_id 
                              WHERE 
                              ${IF(subfocus, "Sub")}${perspective_name}.name NOT IN (${this.state.focus_filter.map(v => "\"" + v + "\"").join(",")})
                              ${IF(subfocus, `AND ${perspective_name}.name = "${this.state.focusCategory}"`)}
                              ${IF(subchart, `AND ${data_name}.name = "${this.state.chartCategory}"`)}
                                GROUP BY Links.${(IF(subchart, "Sub") + data_name).toLowerCase()}_id,  ${IF(subchart, "Sub") + data_name}.name`);
        let max = Math.max(...res.map(v => v.weight));
        res.map(v => {
            v.yellow = isNaN(v.yellow) ? 0 : v.yellow;
            v.green = isNaN(v.green) ? 0 : v.green;
            v.weight = isNaN(v.weight) ? 0 : v.weight;

            let tot = v.yellow + v.green;
            tot = tot === 0 ? 1 : tot;
            // noinspection JSPrimitiveTypeWrapperUsage
            v.yellow /= tot;
            // noinspection JSPrimitiveTypeWrapperUsage
            v.green /= tot;
            // noinspection JSPrimitiveTypeWrapperUsage
            v.weight /= max;
        });
        if(this.state.chartType === "Bar") res.sort((a,b) => a.weight - b.weight);
        return res;

    }

    handleUnselectAll = (filter_list) => {
        this.setState({
            focus_filter: filter_list.map((f) => f.name)
        })
    }

    handleLegendChange = (state) => {
        let greens, yellows;
        greens = !state.selected.Greens;
        yellows = !state.selected.Yellows;
        this.setState({
            greenHidden: greens,
            yellowHidden: yellows
        })
        console.log(state);
    }

    handleSelectAll = () => {
        this.setState({
            focus_filter: [],
        })
    }

    renderList = (perspective_name, data_name, filter_list, formatted_data, focus_color_map={}, focus_text_color_map={}) => {
        if(this.state.perspective === "SDG" && this.state.focusCategory === null){
            return (<Container style={{margin:"auto",  height:"100%", overflowY:"auto", width:"90%"}}>
                    <Row >
                        {filter_list.map((f,i) =>
                            <Col style={{paddingTop:"15px"}} xs={3} md={3} lg={4} xl={3} onClick={e=>this.handleFilterClick(f.name,e)}>
                                <img style={{width:"100%"}} src={"/static/icons"+ (this.state.focus_filter.indexOf(f.name) < 0 ? "" : "_inverted") +"/" + ((i+1) <= 9 ? "0" + (i+1) : (i+1)) + ".png"}/>
                            </Col>
                        )}
                    </Row>
            </Container>)
        }else if (this.state.perspective === "SDG" && this.state.focusCategory !== null){
            console.error("HERE?")
            return (<ListGroup variant={"light"} style={{margin:"auto",  height:"100%", overflowY:"auto", width:"90%"}}>
                {filter_list.map((f,i) => {

                    let c = focus_color_map[f.id];
                    let tc = focus_text_color_map[f.id];
                    return (<ListGroup.Item key={"SDG"+f.id} style={styles.btn_color(c, tc)}  onClick={e=>this.handleFilterClick(f.name,e)} active={this.state.focus_filter.indexOf(f.name) < 0 }>
                        <FormCheck inline checked={this.state.focus_filter.indexOf(f.name) < 0 }>

                        </FormCheck>
                        {f.name}
                    </ListGroup.Item>);
                })}
            </ListGroup>)
        }

        return (<ListGroup variant={"light"} style={{margin:"auto", overflowY:"auto", width:"80%"}}>
            {filter_list.map((f,i) => {

                let c = this.state.focus_filter.indexOf(f.name) < 0 ? "white" : "black";

                return (<ListGroup.Item  style={styles.btn_color("auto", c)}  onClick={e=>this.handleFilterClick(f.name, e)} active={this.state.focus_filter.indexOf(f.name) < 0 }>
                    <FormCheck inline checked={this.state.focus_filter.indexOf(f.name) < 0 }>

                    </FormCheck>
                    {f.name}
                </ListGroup.Item>);
            })}
        </ListGroup>)
    }

    render() {
        let res = this.formatData();


        let perspective_name = this.state.perspective === "SDG" ? "Goal" : "Indicator";
        let data_name = this.state.perspective === "SDG" ? "Indicator" : "Goal";

        let focus_color_map = {};
        let focus_text_color_map = {};
        if(this.state.perspective === "SDG" && this.state.focusCategory !== null){
            let temp = db.exec(`SELECT SubGoal.*, COUNT(IF(val = 1, 1, null)) as yellow, COUNT(IF(val = 2, 2, null)) as green
                            FROM Links
                              JOIN Indicator on Indicator.id = Links.indicator_id 
                              JOIN Goal on Goal.id = Links.goal_id 
                              JOIN SubGoal on SubGoal.id = Links.subgoal_id 
                              JOIN SubIndicator on SubIndicator.id = Links.subindicator_id 
                              WHERE Goal.name = '${this.state.focusCategory}'
                                GROUP BY SubGoal.id`);
            for(let i = 0; i < temp.length; i++){
                let current = temp[i];
                let key = current.id;
                focus_color_map[key] = current.yellow > current.green ?   "#FFDD35" :  "#9ECD5E" ;
                focus_text_color_map[key] = current.yellow > current.green ?  "black" : "black";
            }
        }

        let filter_list = db.exec("select distinct "+ (this.state.focusCategory === null ? "" : "Sub") + `${perspective_name}.name as name, `+ (this.state.focusCategory === null ? "" : "Sub") + `${perspective_name}.id as id from `+  (this.state.focusCategory === null ? perspective_name :
                `${perspective_name} JOIN Sub${perspective_name} on Sub${perspective_name}.${perspective_name.toLowerCase()}_id = ${perspective_name}.id WHERE ${perspective_name}.name = "${this.state.focusCategory}"`
        ));


        return (
            <Container fluid style={{height:"100%"}}>
                <Row style={{height:"100%",}}>
                    <Col sm={12} md={12} xl={4} style={{display:"flex", height:"100%", flexDirection:"column"}}>
                        <Row style={{flex:"0 0 auto"}}>
                            <Button style={{...styles.btn, flexBasis:"50%", maxWidth:"50%", margin:"auto"}} onClick={_=>this.setState({settingsOpen: !this.state.settingsOpen})}>
                                Settings
                            </Button>
                            <Collapse in={this.state.settingsOpen} >
                                <Container fluid>
                                    <Row>
                                        <Col> &nbsp;</Col>
                                    </Row>
                                    <Row >
                                        <Col style={{fontSize:"0.1rem"}}>
                                            <ButtonGroup style={styles.btn} style={{display:"flex", fontSize:"0.1rem"}}>
                                                <Button disabled style={{...styles.btn, flex:"1"}}>
                                                    Perspective
                                                </Button>
                                                <DropdownButton drop={"down"} style={{...styles.btn, flex:"1"}} as={ButtonGroup} title={this.state.perspective}  onSelect={this.handlePerspectiveChange}>
                                                    <Dropdown.Item style={styles.btn} eventKey={"SDG"}>
                                                        SDG
                                                    </Dropdown.Item>
                                                    <Dropdown.Item style={styles.btn} eventKey={"WSC"}>
                                                        WSC
                                                    </Dropdown.Item>
                                                </DropdownButton>
                                            </ButtonGroup>
                                        </Col>
                                        <Col>
                                            <ButtonGroup style={{display:"flex", flexDirection:"row"}}>
                                                <Button disabled style={{...styles.btn, flex:"1"}}>
                                                    Category
                                                </Button>
                                                <DropdownButton drop={"down"} style={{...styles.btn, flex:"1"}} as={ButtonGroup} title={this.state.focusCategory || "Overview"} onSelect={this.handleFocusChange}>
                                                    <Dropdown.Item style={styles.btn} eventKey={"Overview"}>
                                                        Overview
                                                    </Dropdown.Item>
                                                    {this.state.focusCategoryOptions.map(f => {
                                                        return (<Dropdown.Item style={styles.btn} eventKey={f}>
                                                            {f}
                                                        </Dropdown.Item>);
                                                    })}
                                                </DropdownButton>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            &nbsp;
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <ButtonGroup style={{display:"flex"}}>
                                                <Button disabled style={{...styles.btn, flex:"1"}}>
                                                    Chart Category
                                                </Button>
                                                <DropdownButton drop={"down"} style={{...styles.btn, flex:"1"}} as={ButtonGroup} title={this.state.chartCategory || "Overview"} onSelect={this.handleChartCategoryChange}>
                                                    <Dropdown.Item style={styles.btn} eventKey={"Overview"}>
                                                        Overview
                                                    </Dropdown.Item>

                                                    {this.state.chartCategoryOptions.map(f => {
                                                        return (<Dropdown.Item style={styles.btn} eventKey={f}>
                                                            {f}
                                                        </Dropdown.Item>);
                                                    })}
                                                </DropdownButton>
                                            </ButtonGroup>
                                        </Col>
                                        <Col>
                                            <ButtonGroup style={{display:"flex"}}>
                                                <Button disabled style={{...styles.btn, flex:"1"}}>
                                                    Chart Type
                                                </Button>
                                                <DropdownButton drop={"down"} style={{...styles.btn, flex:"1"}} as={ButtonGroup} title={this.state.chartType} onSelect={this.handleChartTypeChange}>
                                                    <Dropdown.Item style={styles.btn} eventKey={"Bar"}>
                                                        Bar
                                                    </Dropdown.Item>
                                                    <Dropdown.Item style={styles.btn} eventKey={"Radial"}>
                                                        Radial
                                                    </Dropdown.Item>
                                                </DropdownButton>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </Container>
                            </Collapse>
                        </Row>
                        <Row style={{flex:"0 0 auto"}}>
                            <div style={{textAlign:"center", width:"100%"}}>
                                {this.state.focusCategory || perspective_name + " Overview"}
                            </div>
                        </Row>
                        <Row>

                            <Button style={{...styles.btn, margin:"auto"}} onClick={this.handleSelectAll}>select all</Button>
                            <Button style={{...styles.btn, margin:"auto"}} onClick={_ => this.handleUnselectAll(filter_list)}>unselect all</Button>
                        </Row>
                        <Row >
                            &nbsp;
                        </Row>
                        <Row style={{overflowY:"hidden", height:"100%", flex:"1 1 auto"}}>
                            {this.renderList(perspective_name, data_name, filter_list, res, focus_color_map, focus_text_color_map)}

                        </Row>
                    </Col>
                    <Col sm={12} md={12} xl={8} style={{maxHeight:"100%", minHeight:"100%"}}>
                            <div style={{height:"100%"}}>
                                {
                                    this.state.chartType === "Bar" ?
                                        <BarChart formattedData={res} filter_list={filter_list} yName={perspective_name} title={this.state.chartCategory || data_name+ " Overview"}/> :
                                        <RadarChart formattedData={res} filter_list={filter_list} title={this.state.chartCategory || data_name + " Overview"}/>
                                }
                            </div>
                    </Col>
                </Row>

            </Container>
        );
    }
}

export default ChartContainerNeat;