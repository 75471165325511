import {Component, } from "react"
import React from "react";
import db from "./util/sql_init";
import ReactEcharts from "echarts-for-react";
import RadarChart from "./RadarChart";
import BarChart from "./BarChart";
import HoverableText from "./HoverableText";
import {Dropdown} from "react-bootstrap"
import {DropdownButton} from "react-bootstrap"
import {ButtonToolbar, Col, Container, ListGroup, Row, FormCheck, ButtonGroup, Button} from "react-bootstrap";

class ChartContainer extends Component {

    state = {
        focus_filter: [],
        focusCategory: null,
        initialOptions: {},
        focusCategoryOptions: [],
        chartCategory: null,
        chartCategoryOptions: [],
        chartType: "Bar",
        perspective: "SDG",
        greenHidden: false,
        yellowHidden: false,

    };

    constructor(){
        super();
        let filter_list = db.exec("column of select distinct Goal.name as name from Goal");
        this.state.focusCategoryOptions = filter_list;
        filter_list = db.exec("column of select distinct Indicator.name as name from Indicator");
        this.state.chartCategoryOptions = filter_list;
    }

    handleFilterClick = (key, event) => {
        let new_filter = [...this.state.focus_filter];
        let index = new_filter.indexOf(key);

        if(index >= 0){
            new_filter.splice(index, 1);
        }else{
            if(index < 0){
                new_filter.push(key);
            }
        }

        this.setState({
            focus_filter: new_filter,
        })
    }


    handleFocusChange = (e) => {
        this.setState({
            focusCategory: e === "Overview" ? null : e,
            focus_filter: [],
        });
    }

    handleChartTypeChange = (e) => {
        this.setState({
            chartType: e,
        });
    };

    handleChartCategoryChange = (e) => {
        this.setState({
            chartCategory: e === "Overview" ? null : e,
        });
    }


    handlePerspectiveChange = (e) => {
        let table_name = e === "SDG" ? "Goal" : "Indicator"
        let data_name = e  === "SDG" ? "Indicator": "Goal";
        this.setState({
            perspective: e,
            focus_filter: [],
            focusCategory: null,
            focusCategoryOptions: db.exec(`column of select distinct ${table_name}.name as name from ${table_name}`),
            chartCategory: null,
            chartCategoryOptions: db.exec(`column of select distinct ${data_name}.name as name from ${data_name}`)
        });

    }

    formatData = () => {
        let perspective_name = this.state.perspective === "SDG" ? "Goal" : "Indicator";
        let data_name = this.state.perspective === "SDG" ? "Indicator" : "Goal";

        let IF = (c,s) => c ? s : "";

        let subfocus = this.state.focusCategory !== null;
        let subchart = this.state.chartCategory !== null;
        let res = db.exec(`SELECT COUNT(IF(val = 1, 1, null)) as yellow, COUNT(IF(val = 2, 1, null)) as green, (COUNT(IF(val = 1, 1, null)) + 2*COUNT(IF(val = 2, 1, null)))/(2*(COUNT(val))) as weight, ${IF(subchart, "Sub") + data_name}.name as name
                            FROM Links
                              JOIN Indicator on Indicator.id = Links.indicator_id 
                              JOIN Goal on Goal.id = Links.goal_id 
                              JOIN SubGoal on SubGoal.id = Links.subgoal_id 
                              JOIN SubIndicator on SubIndicator.id = Links.subindicator_id 
                              WHERE 
                              ${IF(subfocus, "Sub")}${perspective_name}.name NOT IN (${this.state.focus_filter.map(v => "\"" + v + "\"").join(",")})
                              ${IF(subfocus, `AND ${perspective_name}.name = "${this.state.focusCategory}"`)}
                              ${IF(subchart, `AND ${data_name}.name = "${this.state.chartCategory}"`)}
                                GROUP BY Links.${(IF(subchart, "Sub") + data_name).toLowerCase()}_id,  ${IF(subchart, "Sub") + data_name}.name`);
        let max = Math.max(...res.map(v => v.weight));
        res.map(v => {
            let tot = v.yellow + v.green;
            // noinspection JSPrimitiveTypeWrapperUsage
            v.yellow /= tot;
            // noinspection JSPrimitiveTypeWrapperUsage
            v.green /= tot;
            // noinspection JSPrimitiveTypeWrapperUsage
            v.weight /= max;
        });
        if(this.state.chartType === "Bar") res.sort((a,b) => a.weight - b.weight);
        return res;

    }


    handleLegendChange = (state) => {
        let greens, yellows;
        greens = !state.selected.Greens;
        yellows = !state.selected.Yellows;
        this.setState({
            greenHidden: greens,
            yellowHidden: yellows
        })
        console.log(state);
    }

    render() {
        let res = this.formatData();


        let perspective_name = this.state.perspective === "SDG" ? "Goal" : "Indicator";
        let data_name = this.state.perspective === "SDG" ? "Indicator" : "Goal";


        let filter_list = db.exec("column of select distinct "+ (this.state.focusCategory === null ? "" : "Sub") + `${perspective_name}.name as name from ` + (this.state.focusCategory === null ? perspective_name :
                `${perspective_name} JOIN Sub${perspective_name} on Sub${perspective_name}.${perspective_name.toLowerCase()}_id = ${perspective_name}.id WHERE ${perspective_name}.name = "${this.state.focusCategory}"`
        ));

        return (
            <Container fluid>
                <Row>
                    <Col xs={6} md={5} xl={4}>
                        <Row>
                            <ButtonToolbar style={{display:"flex", justifyContent: "space-around", width:"100%"}}>
                                <ButtonGroup>
                                    <Button disabled>
                                        Perspective
                                    </Button>
                                    <DropdownButton as={ButtonGroup} title={this.state.perspective}  onSelect={this.handlePerspectiveChange}>
                                        <Dropdown.Item eventKey={"SDG"}>
                                            SDG
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey={"Indicator"}>
                                            Indicator
                                        </Dropdown.Item>
                                    </DropdownButton>
                                </ButtonGroup>
                                <ButtonGroup>
                                    <Button disabled>
                                        Category
                                    </Button>
                                    <DropdownButton as={ButtonGroup} title={this.state.focusCategory || "Overview"} onSelect={this.handleFocusChange}>
                                        <Dropdown.Item eventKey={"Overview"}>
                                            Overview
                                        </Dropdown.Item>
                                        {this.state.focusCategoryOptions.map(f => {
                                            return (<Dropdown.Item eventKey={f}>
                                                {f}
                                            </Dropdown.Item>);
                                        })}
                                    </DropdownButton>
                                </ButtonGroup>

                                <ButtonGroup>
                                    <Button disabled>
                                        Chart Category
                                    </Button>
                                    <DropdownButton as={ButtonGroup} title={this.state.chartCategory || "Overview"} onSelect={this.handleChartCategoryChange}>
                                        <Dropdown.Item eventKey={"Overview"}>
                                            Overview
                                        </Dropdown.Item>

                                        {this.state.chartCategoryOptions.map(f => {
                                            return (<Dropdown.Item eventKey={f}>
                                                {f}
                                            </Dropdown.Item>);
                                        })}
                                    </DropdownButton>
                                </ButtonGroup>

                                <ButtonGroup>
                                    <Button disabled>
                                        Category
                                    </Button>
                                    <DropdownButton as={ButtonGroup} title={this.state.chartType} onSelect={this.handleChartTypeChange}>
                                        <Dropdown.Item eventKey={"Bar"}>
                                            Bar
                                        </Dropdown.Item>
                                        <Dropdown.Item eventKey={"Radial"}>
                                            Radial
                                        </Dropdown.Item>
                                    </DropdownButton>
                                </ButtonGroup>
                            </ButtonToolbar>
                        </Row>
                        <Row>
                            <div style={{textAlign:"center", width:"100%"}}>
                            {this.state.focusCategory || perspective_name + " Overview"}
                            </div>
                        </Row>
                        <Row style={{overflowY:"auto", maxHeight:"500px"}}>

                            <ListGroup variant={"light"} style={{margin:"auto", width:"80%"}}>
                                {filter_list.map((f,i) => {
                                    return (<ListGroup.Item  onClick={e=>this.handleFilterClick(f,e)} active={this.state.focus_filter.indexOf(f) < 0 }>
                                        <FormCheck inline checked={this.state.focus_filter.indexOf(f) < 0 }>

                                        </FormCheck>
                                        {f}
                                            {/*<HoverableText bottom={i >= filter_list.length/2} text={f} limit={70}/>*/}
                                    </ListGroup.Item>);
                                })}
                            </ListGroup>
                        </Row>
                    </Col>
                    <Col xs={6} md={7} xl={8}>

                        <div style={{height:"600px"}}>
                            {
                                this.state.chartType === "Bar" ?
                                    <BarChart formattedData={res} filter_list={filter_list} yName={perspective_name} title={this.state.chartCategory || data_name+ " Overview"}/> :
                                    <RadarChart formattedData={res} filter_list={filter_list} title={this.state.chartCategory || data_name + " Overview"}/>
                            }
                        </div>
                    </Col>
                </Row>

                </Container>
        );
    }
}

export default ChartContainer;