import React, { Component } from 'react';
import BarChart from "./BarChart";
import db from "./util/sql_init"
import RadarChart from "./RadarChart";
import ChartContainer from "./ChartContainer";
import ChartContainerNeat from "./ChartContainerNeat";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {Navbar, Button} from "react-bootstrap"
import ChartPage from "./ChartPage";

const styles={
  row:{
      maxWidth:"100vw",
      display:"flex",
      flexDirection:"row",
  },
  col:(v, no_pad) => ({
      padding:no_pad === true ? "0" : "2%",
      flexWrap:"nowrap",
      flex:`${v} 1`,
      flexDirection:"column",
      display:"flex",
  }),
    gutter:(v) => ({
        padding:"2%",
        flex:`${v} 1`
    }),
    flip: {
        display: "inline-block",
        "-moz-transform": "scale(-1, 1)",
        "-webkit-transform": "scale(-1, 1)",
        "-o-transform": "scale(-1, 1)",
        "-ms-transform": "scale(-1, 1)",
        "transform": "scale(-1, 1)",
    }

}

class LandingPage extends Component {
  render() {
    return (
        <div>
            <div style={styles.row}>

                <div style={styles.gutter(0.5)}>
                </div>
                <div style={styles.col(10)}>
                    <div>
                        <h2>Background</h2>
                    </div>
                    <div style={styles.row}>
                        <div style={styles.col(10)}>
                            <img src={"/static/leftimg.png"} style={{width:"100%", maxHeight:"300px", paddingTop:"20px"}}/>
                            The concept of Water Sensitive Cities outlines a vision for how we can deliver essential water services in a way that enhances the sustainability, liveability and resilience of our cities and communities.
                        </div>
                        <div style={styles.gutter(0.5)}/>
                        <div style={styles.col(10)}>

                            <img src={"/static/rightimg.png"} style={{width:"100%", maxHeight:"300px"}}/>
                            These same principles of sustainability, liveability and resilience underpin the UN Sustainable Development Goals (SDGs), adopted by all countries in 2015.

                            The 2030 SDGs outline an ambitious agenda for all cities, countries and organisations, providing a framework for moving towards economic productivity in a way that ensures environmental sustainability and social inclusion.
                        </div>
                    </div>
                    <div style={{...styles.row, fontFamily:"monospace"}}>
                        <div style={{...styles.col(10, true), position:"relative"}}>
                            <div style={{...styles.flip, fontFamily:"Arial, sans-serif", fontWeight:100 , textAlign: "left", fontSize:"9em"}}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&#8601;&nbsp;&nbsp;
                            </div>
                        </div>
                        <div style={styles.gutter(0.5)}/>
                        <div style={{...styles.col(10, true),fontFamily:"Arial, sans-serif", position:"relative"}}>
                            <div style={{ fontWeight:100, fontFamily:"Arial, sans-serif", textAlign: "left", fontSize:"9em"}}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&#8601;&nbsp;&nbsp;
                            </div>
                        </div>
                    </div>
                    <div style={styles.row}>

                        <div style={styles.gutter(0.5)}>
                        </div>
                        <div style={styles.col(10)}>
                            <p>
                            This applied research project explores the relationships between the concept of Water Sensitive Cities and the SDGs.

                            The objectives are to:
                            </p>
                            <p>
                            <b>a)</b> Demonstrate the linkages between the WSC and SDG frameworks as a foundation for understanding the potential to harness water sector investments to simultaneously help address particular SDGs; and
                            </p>
                            <p>
                            <b>b)</b>      Articulate the contribution water sensitivity can make to local realisation of the SDGs;
                            </p>
                            <p>
                            From an SDG-centric lens, this work can also provide a rationale for using water as an entry point for realising this very ambitious and far-reaching agenda, and offer a practical pathway for operationalising the SDGs in an integrated and holistic way.

                            In a second phase (TBC), there is the potential to develop a decision-support tool (linked to the WSC Index) that can identify opportunities for implementing water sensitive initiatives in a way that maximises SDG co-benefits
                            </p>
                        </div>
                        <div style={styles.gutter(0.5)}>
                        </div>
                    </div>

                </div>
                <div style={styles.gutter(0.5)}>
                </div>

            </div>
            <div style={styles.row}>
                <p/>
                <p/>
                <p/>
            </div>
          <div style={styles.row}>
              <div style={styles.gutter(0.5)}>
              </div>


              <div style={styles.col(10)}>
                  <div>
                      <h2>Introduction</h2>
                  </div>
                  <row>
                  <img src={"/static/Picture1.png"} align="right" width={"60%"}/>
                      This graph shows the overall alignment between the Water Sensitive City vision and the SDGs - and demonstrates that a WSC approach can help meet all the SDGs to a greater or lesser extent.

                      In revealing where the strongest alignment is (SDGs 11,6 and 15), it also helps identify areas where water sector investment can best be leveraged to achieve SDG co-benefits.
                  </row>
                  <row style={{textAlign:"right"}}>
                      <Button style={{backgroundColor:"#007789"}} onClick={this.props.handleContinueClick}>Continue</Button>
                  </row>
              </div>
              <div style={styles.gutter(0.5)}>

              </div>
          </div>
        </div>
    );
  }
}



export default LandingPage;
