import {Component, } from "react"
import React from "react";
import db from "./util/sql_init";
import ReactEcharts from "echarts-for-react";
import {vmin, vw} from "./RadarChart";

class BarChart extends Component {

    state = {
        greenHidden: false,
        yellowHidden: false,

    };

    constructor(){
        super();
    }




    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
    };


    handleLegendChange = (state) => {
        let greens, yellows, reds;
        greens = !state.selected.Greens;
        yellows = !state.selected.Yellows;
        this.setState({
            greenHidden: greens,
            yellowHidden: yellows,
        })
    }

    render() {
        let res = this.props.formattedData;
        let keys = [];
        if(!this.state.greenHidden){
            keys.push("green");
        }
        if(!this.state.yellowHidden){
            keys.push("yellow");
        }

        let max = Math.max(...res.map(f => keys.map(k => 100*f[k]).reduce((acc, cur) => acc+cur)))

        let categories = res.map(v => v.name);

        let max_val = max;

        max_val = max_val.toFixed(2)
        return (
                <ReactEcharts
                    onEvents={{
                        legendselectchanged: this.handleLegendChange,
                    }}
                    style={{
                        height:"100%",
                        width:"100%",
                    }}
                    option = {{
                        chart:{
                            type: 'bar',
                        },
                        grid:{
                            containLabel:true,
                            right:60,
                            left:"60",
                            top:"110",
                            bottom:"50",
                        },
                        title: {
                            top:"50",
                            right:"45%",
                            text: this.props.title,
                        },
                        tooltip:{
                            trigger:"axis",
                            formatter: function (params) {
                                let format_name = (str) => {
                                    if(str.length < 70){
                                        return str;
                                    }
                                    let split_indexs = [0];
                                    let space_indices = [];
                                    for(let i = 0; i < str.length; i++){
                                        if(str.charAt(i) === " "){
                                            space_indices.push(i);
                                        }
                                    }

                                    for(let i = 100; i < str.length; i+=100){
                                        let greatest_lower = space_indices.map((v,i) => [v,i]).filter(o => o[0] < i).pop();
                                        let least_upper = space_indices.map((v,i) => [v,i]).filter(o => o[0] >= i)[0];
                                        greatest_lower = greatest_lower || 0;
                                        least_upper = least_upper || str.length;
                                        let du = least_upper[0] - i;
                                        let dl = i - greatest_lower[0];
                                        if(du < dl){
                                            split_indexs.push(least_upper[0])
                                        }else{
                                            split_indexs.push(greatest_lower[0])
                                        }
                                    }

                                    split_indexs.push(str.length)
                                    let final_string = "";
                                    for(let i = 0; i < split_indexs.length - 1; i++){
                                        let current = split_indexs[i];
                                        let next = split_indexs[i+1];
                                        final_string += str.substring(current, next) + "<br/>";
                                    }
                                    return final_string
                                }

                                let s = "<div>" + format_name(params[0].name) + "</br>";
                                let sum = 0;

                                for(let i = 0; i < params.length; i++){
                                    sum += params[i].value;
                                }
                                for(let i = 0; i < params.length; i++){
                                    s += params[i].seriesName + ": " + params[i].value.toFixed(1) + "% ("+ (100*params[i].value/(sum === 0 ? 1 : sum)).toFixed(1) +"%)</br>";
                                }
                                s += "Sum: " + sum.toFixed(1) + "%</br>";
                                s += "</div>";
                                return s;
                            }
                        },
                        xAxis: {
                            min:0,
                            max:max_val,
                            axisPointer:{
                              show: false,
                            },
                            axisLabel:{
                                margin:30,
                                fontSize:15,
                              verticalAlign:"top",
                            },
                            nameGap:60,
                            nameTextStyle:{
                                fontSize:20
                            },
                            name: "Relative Impact",
                            nameLocation: "center",
                        },
                        yAxis: [{
                            type:"category",
                            data: categories,
                            axisLabel:{

                                formatter:(str)=>{
                                    if(str.length < 50){
                                        return str;
                                    }else{
                                        str =  str.substring(0, 70) +"...";
                                    }
                                    let split_indexs = [0];
                                    let space_indices = [];
                                    for(let i = 0; i < str.length; i++){
                                        if(str.charAt(i) === " "){
                                            space_indices.push(i);
                                        }
                                    }

                                    for(let i = 40; i < str.length; i+=40){
                                        let greatest_lower = space_indices.map((v,i) => [v,i]).filter(o => o[0] < i).pop();
                                        let least_upper = space_indices.map((v,i) => [v,i]).filter(o => o[0] >= i)[0];
                                        greatest_lower = greatest_lower || 0;
                                        least_upper = least_upper || str.length;
                                        let du = least_upper[0] - i;
                                        let dl = i - greatest_lower[0];
                                        if(du < dl){
                                            split_indexs.push(least_upper[0])
                                        }else{
                                            split_indexs.push(greatest_lower[0])
                                        }
                                    }
                                    split_indexs.push(str.length)
                                    let final_string = "";
                                    for(let i = 0; i < split_indexs.length - 1; i++){
                                        let current = split_indexs[i];
                                        let next = split_indexs[i+1];
                                        final_string += str.substring(current, next) + "\n";
                                    }
                                    return final_string
                                }
                            },
                            name: this.props.yName || "",
                            nameLocation: "right",
                            nameRotate: 270,
                            nameTextStyle:{
                                fontSize:vw(0.4),
                            }
                        },{

                        }],
                        legend: {

                            top: "75",
                        },
                        plotOptions: {
                            series: {
                                stacking: 'normal'
                            },
                            bar: {
                                pointWidth:32,
                            }
                        },
                        series: [{
                            id:"greens",
                            name: 'Greens',
                            type: "bar",
                            stack: "main",
                            color:"#9ECD5E",
                            data: res.map(v => v.green*100*v.weight)
                        },{
                            id:"yellows",
                            name: 'Yellows',
                            type: "bar",
                            stack: "main",
                            color: "#FFDD35",
                            data: res.map(v => v.yellow*100*v.weight)
                        }, ]
                    }}

                    opts={{renderer:'svg'}}
                />

        );
    }
}

export default BarChart;